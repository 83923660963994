import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, HostListener, Inject } from '@angular/core';

import { BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { followLink } from '../../shared/utils/follow-link-config.model';
import { SortDirection, SortOptions } from '../../core/cache/models/sort-options.model';
import { CommunityDataService } from '../../core/data/community-data.service';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { RemoteData } from '../../core/data/remote-data';
import { Community } from '../../core/shared/community.model';
import { fadeInOut } from '../../shared/animations/fade';
import { PaginationComponentOptions } from '../../shared/pagination/pagination-component-options.model';
import { hasValue } from '../../shared/empty.util';
import { switchMap } from 'rxjs/operators';
import { PaginationService } from '../../core/pagination/pagination.service';
import { AppConfig, APP_CONFIG } from 'src/config/app-config.interface';

/**
 * this component renders the Top-Level Community list
 */
@Component({
  selector: 'ds-top-level-community-list',
  styleUrls: ['./top-level-community-list.component.scss'],
  templateUrl: './top-level-community-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut]
})

export class TopLevelCommunityListComponent implements OnInit, OnDestroy, AfterViewInit {

  // list of top level communities
  public topLevelCommunities: Community[];
  private topLevelCommunitiesSubject: BehaviorSubject<Community[]> = new BehaviorSubject<Community[]>([]);
  topLevelCommunities$: Observable<Community[]> = this.topLevelCommunitiesSubject.asObservable();

  // number of cols for top-level-community grid
  cols: number = 4;
  colsWidth: number = 250;

  // mat grid list (required for window resizing)
  private topLevelCommunityGridList: ElementRef;
  @ViewChild('topLevelCommunityGridList', {read: ElementRef}) set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
        this.topLevelCommunityGridList = content;
    }
  }

  /**
   * A list of remote data objects of all top communities
   */
  communitiesRD$: BehaviorSubject<RemoteData<PaginatedList<Community>>> = new BehaviorSubject<RemoteData<PaginatedList<Community>>>({} as any);

  /**
   * The pagination configuration
   */
  config: PaginationComponentOptions;

  /**
   * The pagination id
   */
  pageId = 'tl';

  /**
   * The sorting configuration
   */
  sortConfig: SortOptions;

  /**
   * The subscription to the observable for the current page.
   */
  currentPageSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private cds: CommunityDataService,
    private paginationService: PaginationService
  ) {
    this.config = new PaginationComponentOptions();
    this.config.id = this.pageId;
    this.config.pageSize = appConfig.homePage.topLevelCommunityList.pageSize;
    this.config.currentPage = 1;
    this.sortConfig = new SortOptions('dc.title', SortDirection.ASC);
  }

  ngAfterViewInit(): void {
    this.setGridCols();
  }

  ngOnInit() {
    this.initPage();
  }

  /**
   * Update the list of top communities
   */
  initPage() {
    const pagination$ = this.paginationService.getCurrentPagination(this.config.id, this.config);
    const sort$ = this.paginationService.getCurrentSort(this.config.id, this.sortConfig);

    this.currentPageSubscription = observableCombineLatest([pagination$, sort$]).pipe(
      switchMap(([currentPagination, currentSort]) => {
        return this.cds.findTop({
          currentPage: currentPagination.currentPage,
          elementsPerPage: currentPagination.pageSize,
          sort: {field: currentSort.field, direction: currentSort.direction}},
          followLink<Community>("logo"));
      })
    ).subscribe((results: RemoteData<PaginatedList<Community>>) => {
      this.communitiesRD$.next(results);

      // unpack result and feed the data to their subjects
      if (results.hasSucceeded && results.payload !== undefined) {
        this.topLevelCommunities = results.payload.page;
        this.topLevelCommunitiesSubject.next(this.topLevelCommunities);
      }
    });
  }

  /**
   * Unsubscribe the top list subscription if it exists
   */
  private unsubscribe() {
    if (hasValue(this.currentPageSubscription)) {
      this.currentPageSubscription.unsubscribe();
    }
  }

  /**
   * Clean up subscriptions when the component is destroyed
   */
  ngOnDestroy() {
    this.unsubscribe();
    this.paginationService.clearPagination(this.config.id);
  }

  setGridCols() {
    if (this.topLevelCommunityGridList != undefined) {
      let width = this.topLevelCommunityGridList.nativeElement.offsetWidth;
      this.cols = (width / this.colsWidth) as number;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) { this.setGridCols(); }
}
