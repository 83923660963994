<ds-themed-loading *ngIf="(loading$ | async)"></ds-themed-loading>
<div *ngIf="!(loading$ | async)">
  <span class="dropdown-item-text" [class.pl-0]="inExpandableNavbar">
    {{dsoNameService.getName(user$ | async)}}<br>
    <span class="text-muted">{{(user$ | async)?.email}}</span>
  </span>
  <a [ngClass]="inExpandableNavbar ? 'nav-item nav-link' : 'dropdown-item'" [routerLink]="[profileRoute]" routerLinkActive="active">{{'nav.profile' | translate}}</a>
  <!-- <a [ngClass]="inExpandableNavbar ? 'nav-item nav-link' : 'dropdown-item'" [routerLink]="[mydspaceRoute]" routerLinkActive="active">{{'nav.mydspace' | translate}}</a> -->
  <a [ngClass]="inExpandableNavbar ? 'nav-item nav-link' : 'dropdown-item'" [routerLink]="[mydspaceRoute]" [queryParams]="{configuration: 'workspace'}" routerLinkActive="active">{{'nav.opara.data' | translate}}</a>
  <a [ngClass]="inExpandableNavbar ? 'nav-item nav-link' : 'dropdown-item'" [routerLink]="[mydspaceRoute]" [queryParams]="{configuration: 'workflow'}" routerLinkActive="active">{{'nav.opara.tasks' | translate}}</a>
  <a [ngClass]="inExpandableNavbar ? 'nav-item nav-link' : 'dropdown-item'" [routerLink]="[subscriptionsRoute]" routerLinkActive="active">{{'nav.subscriptions' | translate}}</a>

  <div class="dropdown-divider"></div>
  <ds-log-out *ngIf="!inExpandableNavbar" data-test="log-out-component"></ds-log-out>
</div>


